import { Link } from 'react-router-dom';
import { TOKEN_SHORTCUT, ROUTES } from 'shared/constants';
import { UITypography } from 'shared/ui';
import { formatDonuts } from 'shared/utils';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import { useGlobalStore, useGameStore } from 'shared/store';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down-icon.svg';

export const Limit = () => {
  const { t } = useTranslation();
  const { user } = useGlobalStore();
  const { todayCurrentLimit } = useGameStore();

  return (
    <div className={styles.limitRoot}>
      <img src="/three-small-donuts.png" width={40} height={40} />
      <div className={styles.limitContainer}>
        <div className={styles.limit}>
          <UITypography>{t('main_screen.your_limit')}</UITypography>

          <UITypography fontWeight="bold">{`${todayCurrentLimit ? formatDonuts(todayCurrentLimit) : 0}/${formatDonuts(user.daily_limit)} ${TOKEN_SHORTCUT}`}</UITypography>
        </div>

        <Link to={ROUTES.FRIENDS}>
          <UITypography iconRight={<ArrowDownIcon className={styles.arrowRight} />}>
            {t('main_screen.increase_limit')}
          </UITypography>
        </Link>
      </div>
    </div>
  );
};
