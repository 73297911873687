import { InviteLinkCopiedModal } from 'components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInviteLink } from 'shared/hooks';
import { UIButton } from 'shared/ui';
import { copyToClipboard, copyTextLegacy } from 'shared/utils';

export const InviteFriendsButton = () => {
  const { t } = useTranslation();
  const inviteLink = useInviteLink();
  const [open, setOpen] = useState(false);

  const onCopy = async () => {
    try {
      await copyToClipboard(inviteLink);
      setOpen(true);
    } catch (error) {
      copyTextLegacy(inviteLink);
      setOpen(true);
    }
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <InviteLinkCopiedModal onClose={onClose} visible={open} />
      <UIButton onClick={onCopy} fullwidth>
        {t('friends.invite_friend')}
      </UIButton>
    </>
  );
};
