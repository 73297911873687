import styles from './style.module.scss';
import { GamePanel } from 'components';
import { useVisibilityChange } from 'shared/hooks';
import { GameContainer } from './game-container';
import { useGame } from './hooks/use-game';

export const GameSessionModule = () => {
  useVisibilityChange();

  const { stopGame } = useGame();

  return (
    <div className={styles.root}>
      <GamePanel stopGame={stopGame} />
      <GameContainer stopGame={stopGame} />
    </div>
  );
};
