import { DonutEntity, LeagueType, LeagueVariantEntity } from 'shared/types';
import bronzeCoinIcon from 'assets/images/bronze-coin.png';
import diamondCoinIcon from 'assets/images/diamond-coin.png';
import goldCoinIcon from 'assets/images/gold-coin.png';
import silverCoinIcon from 'assets/images/silver-coin.png';

export const MAX_APP_WIDTH = 390;
export const MAX_SELECT_WIDTH = 356;
export const SELECT_MENU_GAP = 8;
export const DEFAULT_FINGER_SLIDER_CONFIG = {
  step: 5,
  min: 39,
  max: 59,
};
export enum SUPPORTED_LANGUAGES {
  EN = 'en',
  RU = 'ru',
}
export const LANG_SELECT_OPTIONS = [
  { label: SUPPORTED_LANGUAGES.RU.toUpperCase(), value: SUPPORTED_LANGUAGES.RU },
  { label: SUPPORTED_LANGUAGES.EN.toUpperCase(), value: SUPPORTED_LANGUAGES.EN },
];
export const TOKEN_SHORTCUT = 'DON';

export enum METHODS {
  POST = 'POST',
  GET = 'GET',
  DELETE = 'DELETE',
  PUT = 'PUT',
}

export const SYMBOLS = {
  DOLLAR: '$',
  NEARLY: '~',
};

export const DONUT_TYPES: DonutEntity['type'][] = [
  'blue',
  'chocolate',
  'orange',
  'purple',
  'yellow',
];
export const DONUT_SIZES: DonutEntity['size'][] = [29, 34, 39, 44, 49, 54, 59];
export const DAY_IN_MS = 86400000;
export const FLY_SIZE = 80;
export const DONUT_INITIAL_Y = -140;

export const LEAGUE_VARIANTS: Record<LeagueType, LeagueVariantEntity> = {
  bronze: {
    type: 'bronze',
    name: 'friends.bronze_league',
    id: 1,
    bbl_by_ref: 3600,
    min_ref: 1,
    max_ref: 10,
    icon: bronzeCoinIcon,
  },
  silver: {
    type: 'silver',
    name: 'friends.silver_league',
    id: 2,
    bbl_by_ref: 360,
    min_ref: 11,
    max_ref: 100,
    icon: silverCoinIcon,
  },
  gold: {
    type: 'gold',
    name: 'friends.gold_league',
    id: 3,
    bbl_by_ref: 180,
    min_ref: 101,
    max_ref: 1000,
    icon: goldCoinIcon,
  },
  diamond: {
    type: 'diamond',
    name: 'friends.diamond_league',
    id: 4,
    bbl_by_ref: 36,
    min_ref: 1001,
    max_ref: null,
    icon: diamondCoinIcon,
  },
};

export const REFS_LIST_LIMIT = 5;

export const TOKEN_USDT = 'USDT';

export const TASK_STATUS = {
  TO_DO: 'to_do',
  PENDING: 'pending',
  DONE: 'done',
};

export const MAX_FREEZE_COOLDOWN = 8000; //milliseconds

export const DONUT_AWARD_VALUES = [
  { value: 80, chance: 3.9 },
  { value: 70, chance: 5.57 },
  { value: 60, chance: 7.97 },
  { value: 50, chance: 11.38 },
  { value: 40, chance: 16.25 },
  { value: 30, chance: 10 },
  { value: 20, chance: 15 },
  { value: 10, chance: 30 },
];
