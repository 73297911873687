import styles from './style.module.scss';
import { Outlet } from 'react-router-dom';
import Container from './container';
import { AppHeader, LoadingContainer, Navbar } from 'components';
import { Suspense } from 'react';

export const MainLayout = () => {
  return (
    <div className={styles.root}>
      <AppHeader />

      <Container>
        <Suspense fallback={<LoadingContainer isGlobal={false} />}>
          <Outlet />
        </Suspense>
      </Container>

      <Navbar />
      {/* <GameReadyModal /> */}
    </div>
  );
};
