import { UIButton, UIModal, UITypography } from 'shared/ui';
import styles from './style.module.scss';
import threeDonuts from 'assets/images/three-donuts.png';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'shared/constants';
import { useNavigate } from 'react-router-dom';
import { useGameStore } from 'shared/store';

export const GameReadyModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { gameReady, setGameReady } = useGameStore();

  const onClose = () => {
    setGameReady(false);
  };

  const goPlay = () => {
    navigate(ROUTES.SESSION);
    onClose();
  };

  return (
    <UIModal visible={gameReady} onClose={onClose} detent="content-height">
      <div className={styles.root}>
        <img src={threeDonuts} alt="" width={264} height={170} />
        <UITypography variant="H1"> {t('game_ready_modal')}</UITypography>
        <UIButton fullwidth onClick={goPlay}>
          {t('common.play')}
        </UIButton>
      </div>
    </UIModal>
  );
};
