import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import { ROUTES, TOKEN_SHORTCUT } from 'shared/constants';
import { UITypography, UIButton } from 'shared/ui';
import threeDonutsImage from 'assets/images/three-donuts.png';
import { useNavigate } from 'react-router-dom';
import { useGameStore } from 'shared/store';
import { useEffect } from 'react';
import { formatDonuts } from 'shared/utils';
import { Scale, Swipe } from 'shared/ui/animations';

export const CongratulationsModule = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { currentDonutsCount, setCurrentDonutsCount } = useGameStore();

  const goToMain = () => {
    navigate(ROUTES.MAIN, { replace: true });
  };

  useEffect(() => {
    return () => {
      setCurrentDonutsCount(0);
    };
  }, []);

  return (
    <div className={styles.root}>
      <Scale>
        <img src={threeDonutsImage} alt="" width={264} height={170} />
      </Scale>

      <Swipe direction="horizontal" from="left">
        <div className={styles.text}>
          <UITypography component="p" variant="H1">
            {t('congratulations_screen.your_result')}
          </UITypography>
          <UITypography
            variant="H1"
            component="p"
            color="yellow"
            className={styles.donutsCount}
          >{`+${formatDonuts(currentDonutsCount)} ${TOKEN_SHORTCUT}`}</UITypography>
        </div>
      </Swipe>
      <Swipe direction="horizontal" from="right" style={{ width: '100%' }}>
        <UIButton fullwidth onClick={goToMain}>
          {t('congratulations_screen.back_to_main')}
        </UIButton>
      </Swipe>
    </div>
  );
};
