import { useCallback, useEffect, useState } from 'react';
import { FLY_SIZE, MAX_FREEZE_COOLDOWN } from 'shared/constants';
import { useGameStore, useGlobalStore } from 'shared/store';
import { FlyEntity } from 'shared/types';
import { v4 } from 'uuid';

export const useFlies = () => {
  const [flies, setFlies] = useState<FlyEntity[]>([]);
  const { gameActive, setFrozen, settings, increaseCatchedBees } = useGameStore();
  const [freezeCooldown, setFreezeCooldown] = useState(settings.cooldown_duration * 1000);
  const { appVisible } = useGlobalStore();

  const onFlyClick = useCallback(() => {
    setFrozen(true);
    setFreezeCooldown((prev) => (prev < MAX_FREEZE_COOLDOWN ? prev + 1000 : MAX_FREEZE_COOLDOWN));
    increaseCatchedBees(1);
  }, []);

  const addFly = useCallback((screenWidth: number, screenHeight: number) => {
    const id = `fly-id${v4()}`;

    const startX = Math.random() < 0.5 ? -FLY_SIZE : screenWidth + FLY_SIZE;
    const startY = Math.random() * (screenHeight - FLY_SIZE);
    const endX = startX > 0 ? -FLY_SIZE : screenWidth + FLY_SIZE;
    const endY = Math.random() * (screenHeight - FLY_SIZE);
    const deltaX = endX - startX;
    const deltaY = endY - startY;
    const angleInRadians = Math.atan2(deltaY, deltaX);
    const rotation = angleInRadians * (180 / Math.PI) + 90;

    setFlies((prev) => [...prev, { id, startX, startY, endX, endY, rotation }]);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!gameActive || !appVisible) {
        clearInterval(interval);
        return;
      }

      addFly(window.innerWidth, window.innerHeight);
    }, settings.fall_bee_interval * 1000);

    return () => clearInterval(interval);
  }, [gameActive, appVisible]);

  return { flies, onFlyClick, freezeCooldown };
};
