import { ENDPOINTS } from 'shared/constants';
import { AchievementEntity, AchievementType, TaskEntity } from 'shared/types';
import { httpClientAuthorized } from '../httpClient';

export const getTasks = async () => {
  return await httpClientAuthorized.get<TaskEntity[]>(ENDPOINTS.GET_TASKS);
};

export const completeTask = async (taskId: string) => {
  return await httpClientAuthorized.post<TaskEntity>(ENDPOINTS.COMPLETE_TASK(taskId));
};

export const getAchievements = async (type: AchievementType) => {
  return await httpClientAuthorized.get<AchievementEntity[]>(ENDPOINTS.GET_ACHIEVEMENTS(type));
};
