import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UIButton, UIModal } from 'shared/ui';
import { Swipe } from 'shared/ui/animations';
import { Content } from './content';

export const GetMoreBBLModal = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const modalRef = useRef<HTMLDivElement | null>(null);

  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onFocus = useCallback(() => {
    if (!modalRef.current) return;
    modalRef.current.style.top = `0px`;
  }, []);

  const onBlur = useCallback(() => {
    if (!modalRef.current) return;
    modalRef.current.style.top = `auto`;
  }, []);

  return (
    <>
      <Swipe from="right" direction="horizontal">
        <UIButton fullwidth onClick={() => setOpen(true)}>
          {t('progress.get_more_bbl')}
        </UIButton>
      </Swipe>

      <UIModal ref={modalRef} visible={open} onClose={onClose} detent="content-height">
        <Content onClose={onClose} onFocus={onFocus} onBlur={onBlur} />
      </UIModal>
    </>
  );
};
