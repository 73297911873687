import { AchievementEntity, TaskEntity } from './../types/common';
import { create } from 'zustand';

type ProgressStore = {
  tasks: TaskEntity[];
  staticAchievements: AchievementEntity[];
  dynamicAchievements: AchievementEntity[];
  setTasks: (tasks: TaskEntity[]) => void;
  setStaticAchievements: (achievements: AchievementEntity[]) => void;
  setDynamicAchievements: (achievements: AchievementEntity[]) => void;
  updateTask: (task: TaskEntity) => void;
};

export const useProgressStore = create<ProgressStore>((set) => ({
  tasks: [],
  staticAchievements: [],
  dynamicAchievements: [],
  setTasks: (tasks) => set({ tasks }),
  setStaticAchievements: (staticAchievements) => set({ staticAchievements }),
  setDynamicAchievements: (dynamicAchievements) => set({ dynamicAchievements }),
  updateTask: (task) =>
    set((state) => {
      const filteredTasks = state.tasks.filter((t) => t.id !== task.id);
      return { tasks: [...filteredTasks, task] };
    }),
}));
