export const copyToClipboard = async (text: string) => {
  if (navigator.permissions && navigator.permissions.query) {
    await navigator.permissions.query({ name: 'clipboard-write' as any });
  }

  await navigator.clipboard.writeText(text);
};

export const copyTextLegacy = (text: string) => {
  const tempInput = document.createElement('textarea');
  tempInput.value = text;
  tempInput.style.position = 'absolute';
  tempInput.style.opacity = '0';
  document.body.appendChild(tempInput);
  tempInput.select();
  document.execCommand('copy');
  document.body.removeChild(tempInput);
};
