import { BackButton } from '@vkruglikov/react-telegram-web-app';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from 'shared/constants';

const ROUTES_WITHOUT_BACK_BUTTON = [
  ROUTES.MAIN,
  ROUTES.SESSION,
  ROUTES.CHOOSE_LANGUAGE,
  ROUTES.CONGRATULATIONS,
];

type Props = {
  children: React.ReactNode;
};

export const TgBackButtonProvider = ({ children }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const showButton = !ROUTES_WITHOUT_BACK_BUTTON.some((route) => route === location.pathname);

  return (
    <>
      {showButton && <BackButton onClick={() => navigate(-1)} />}
      {children}
    </>
  );
};
