import styles from './style.module.scss';
import { Link } from 'react-router-dom';
import { ROUTES, TOKEN_SHORTCUT } from 'shared/constants';
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg';
import { UserWidget } from 'components';
import { UITypography } from 'shared/ui';
import { formatDonuts } from 'shared/utils';
import { useTranslation } from 'react-i18next';
import { useGlobalStore } from 'shared/store';

export const AppHeader = () => {
  const { t } = useTranslation();
  const { user } = useGlobalStore();

  return (
    <div className={styles.header}>
      <UserWidget />

      <div className={styles.innerContainer}>
        <div className={styles.balance}>
          <UITypography>{t('common.balance')}</UITypography>
          <UITypography fontWeight="bold">{`${formatDonuts(user.bbl)} ${TOKEN_SHORTCUT}`}</UITypography>
        </div>

        <Link to={ROUTES.SETTINGS} className={styles.settingsButton}>
          <SettingsIcon />
        </Link>
      </div>
    </div>
  );
};
