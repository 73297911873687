import { useCallback, useEffect, useRef, useState } from 'react';
import {
  DONUT_TYPES,
  DONUT_SIZES,
  DEFAULT_FINGER_SLIDER_CONFIG,
  CS_KEYS,
  DONUT_AWARD_VALUES,
} from 'shared/constants';
import { getRandomBoolean, getRandomValueWithChance, randomNumberBetween } from 'shared/utils';
import { useGameStore, useGlobalStore } from 'shared/store';
import { DayLimitDonut, DonutEntity, DonutSize } from 'shared/types';
import { v4 } from 'uuid';
import { useCloudStorage } from '@vkruglikov/react-telegram-web-app';

type useDonutsProps = {
  stopGame: (limitReached?: boolean) => Promise<void>;
};

export const useDonuts = ({ stopGame }: useDonutsProps) => {
  const [donuts, setDonuts] = useState<DonutEntity[]>([]);
  const {
    gameActive,
    currentDonutsCount,
    increaseDonutsCount,
    settings,
    increaseFailedDonuts,
    currentDonutFallSpeed,
  } = useGameStore();
  const { user, appVisible } = useGlobalStore();
  const { setItem, getItem } = useCloudStorage();
  const [limit, setLimit] = useState<DayLimitDonut | null>(null);
  const requiredMinDonuts = useRef(25);

  const userFingerSize = parseInt(user.finger_size || '') || DEFAULT_FINGER_SLIDER_CONFIG.min;

  const deleteDonut = (donutId: string) => {
    setDonuts((prev) => prev.filter((donutItem) => donutItem.id !== donutId));
  };

  const handleDonutClick = useCallback(
    (donut: DonutEntity) => {
      if (userFingerSize === donut.size) {
        increaseDonutsCount(donut.value);
        return { correctDonut: true, deleteDonut };
      }

      increaseFailedDonuts(1);
      return { correctDonut: false, deleteDonut };
    },
    [userFingerSize],
  );

  const handleDonutAnimationEnd = useCallback((donutId: string) => {
    deleteDonut(donutId);
  }, []);

  const addDonut = useCallback(() => {
    const id = v4();
    const type = DONUT_TYPES[randomNumberBetween(0, DONUT_TYPES.length - 1)];
    const positionX = randomNumberBetween(0, window.innerWidth - 100);
    let size = DONUT_SIZES[randomNumberBetween(0, DONUT_SIZES.length - 1)];
    const value = getRandomValueWithChance(DONUT_AWARD_VALUES);

    if (getRandomBoolean() && getRandomBoolean() && requiredMinDonuts.current > 0) {
      size = userFingerSize as DonutSize;
      requiredMinDonuts.current -= 1;
    }

    setDonuts((prev) => [
      ...prev,
      { id, type, positionX, size, value, fallDuration: currentDonutFallSpeed },
    ]);
  }, [userFingerSize, currentDonutFallSpeed]);

  useEffect(() => {
    const getLimit = async () => {
      try {
        const res = await getItem(CS_KEYS.TODAY_DONUTS_COUNT);

        if (res) {
          setLimit(JSON.parse(res));
        }
      } catch (error) {
        console.log(error);
      }
    };
    getLimit();
  }, []);

  useEffect(() => {
    const finishgame = async () => {
      const limitDonuts = limit?.donuts || 0;

      if (limitDonuts + currentDonutsCount >= Math.floor(user.daily_limit)) {
        await setItem(CS_KEYS.DAY_COOLDOWN, 'true');
        await stopGame(true);
      }
    };
    finishgame();
  }, [currentDonutsCount, user.daily_limit, limit]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!gameActive || !appVisible) {
        clearInterval(interval);
        return;
      }

      addDonut();
    }, settings.fall_donuts_interval * 1000);

    return () => clearInterval(interval);
  }, [gameActive, appVisible, addDonut]);

  return {
    donuts,
    handleDonutClick,
    handleDonutAnimationEnd,
  };
};
