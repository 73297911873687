import styles from './style.module.scss';
import { UITypography } from 'shared/ui';
import { useTranslation } from 'react-i18next';

export const Boosters = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.boostersRoot}>
      <div className={styles.overflowContainer}>
        <div className={styles.boostersContainer}>
          <div className={styles.booster}>
            <img
              src="/lightning.png"
              alt=""
              className={styles.boosterImage}
              width={60}
              height={60}
            />
            <UITypography>{t('common.soon')}</UITypography>
          </div>
          <div className={styles.booster}>
            <img
              src="/lightning.png"
              alt=""
              className={styles.boosterImage}
              width={60}
              height={60}
            />
            <UITypography>{t('common.soon')}</UITypography>
          </div>
          <div className={styles.booster}>
            <img
              src="/lightning.png"
              alt=""
              className={styles.boosterImage}
              width={60}
              height={60}
            />
            <UITypography>{t('common.soon')}</UITypography>
          </div>
          <div className={styles.booster}>
            <img
              src="/lightning.png"
              alt=""
              className={styles.boosterImage}
              width={60}
              height={60}
            />
            <UITypography>{t('common.soon')}</UITypography>
          </div>
        </div>
      </div>

      <UITypography fontWeight="bold">{t('main_screen.boosters_coming_soon')}</UITypography>
    </div>
  );
};
