import axios, { CreateAxiosDefaults } from 'axios';
import { appSessionStorage } from 'shared/service';

const defaultHttpClientConfig: CreateAxiosDefaults = {
  baseURL: process.env.REACT_APP_BOBE_API,
  headers: {
    'Content-Type': 'application/json',
  },
};

export const httpClientAuthorized = axios.create(defaultHttpClientConfig);
export const httpClientUnAuthorized = axios.create(defaultHttpClientConfig);

httpClientAuthorized.interceptors.request.use(
  (config) => {
    const accessToken = appSessionStorage.getToken();
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
