import { useTranslation } from 'react-i18next';
import { COMMON_PRELOAD_ASSETS } from 'shared/constants';
import { UITypography } from 'shared/ui';
import { Scale, Swipe } from 'shared/ui/animations';
import starsImage from 'assets/images/stars.png';

export const MarketScreen = () => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        flexDirection: 'column',
        gap: 10,
        paddingRight: 17,
        textAlign: 'center',
      }}
    >
      <Scale style={{ width: '100%' }}>
        <div
          style={{
            minHeight: 348,
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={COMMON_PRELOAD_ASSETS.bobeHero2}
            alt=""
            style={{ display: 'block' }}
            width={300}
          />
          <img
            src={starsImage}
            alt=""
            style={{
              position: 'absolute',
            }}
          />
        </div>
      </Scale>

      <Swipe from="left" direction="horizontal">
        <UITypography variant="H1">{t('common.in_development')}</UITypography>
      </Swipe>
    </div>
  );
};
