import { useEffect } from 'react';
import { useGlobalStore } from 'shared/store';

export const useVisibilityChange = () => {
  const { setAppVisibility } = useGlobalStore();

  useEffect(() => {
    const handleVisibilityChange = () => {
      setAppVisibility(!document.hidden);
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
};
