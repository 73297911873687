import styles from './style.module.scss';
import { UIButton, UIDivider, UITypography } from 'shared/ui';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReferralsStore } from 'shared/store';
import { ReferralEntity } from 'shared/types';
import { ReactComponent as UserTickIcon } from 'assets/icons/user-tick.svg';
import { getRefs } from 'shared/api';
import { LEFT_SWIPE_CHILD_VARIANTS, REFS_LIST_LIMIT, TOKEN_SHORTCUT } from 'shared/constants';
import { UserWidget } from 'components';
import { Spinner } from 'components/loading/spinner';
import { m } from 'framer-motion';

type ReferralsListProps = {
  initialLoading: boolean;
};

export const ReferralsList = ({ initialLoading }: ReferralsListProps) => {
  const { t } = useTranslation();
  const { totalRefs, referrals, totalLimitBBL } = useReferralsStore();

  const [refList, setRefList] = useState<ReferralEntity[]>([]);
  const [loading, setLoading] = useState(false);
  const offset = useRef(REFS_LIST_LIMIT);

  const loadMore = async () => {
    try {
      setLoading(true);
      const res = await getRefs(REFS_LIST_LIMIT, offset.current);
      setRefList((prev) => [...prev, ...res.data.data]);
      offset.current = offset.current + REFS_LIST_LIMIT;
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (refList.length < 1) {
      setRefList(referrals);
    }
  }, [referrals, refList.length]);

  if (initialLoading) {
    return (
      <div className={styles.root}>
        <UITypography
          className={styles.invitedFriends}
          iconLeft={<UserTickIcon />}
        >{`${t('friends.invited_friends')} (${totalRefs})`}</UITypography>
        <div className={styles.initialLoading}>
          <Spinner size={30} />
        </div>
      </div>
    );
  }

  return (
    <m.div
      className={styles.root}
      variants={LEFT_SWIPE_CHILD_VARIANTS}
      initial="hidden"
      animate="visible"
    >
      <UITypography
        className={styles.invitedFriends}
        iconLeft={<UserTickIcon />}
      >{`${t('friends.invited_friends')} (${totalRefs})`}</UITypography>

      {totalRefs > 0 && (
        <>
          <UITypography component="p">
            <UITypography>{t('friends.you_earned')}</UITypography>
            <UITypography fontWeight="bold">{` ${totalLimitBBL} ${TOKEN_SHORTCUT} `}</UITypography>
            <UITypography>{t('friends.from_your_friends')}</UITypography>
          </UITypography>

          <div className={styles.listAndButtonContainer}>
            {refList.map((referral, index) => (
              <div className={styles.list} key={referral.uuid}>
                <div style={{ padding: '12px 0' }}>
                  <UserWidget
                    username={referral.username || referral.first_name}
                    isReferral
                    isApproved={referral.is_approved}
                    bblCount={referral.bbl}
                  />
                </div>

                {index !== refList.length - 1 && <UIDivider />}
              </div>
            ))}

            {refList.length < totalRefs && (
              <UIButton
                variant="text"
                disabled={loading}
                onClick={loadMore}
                className={styles.loadMoreButton}
              >
                {t('common.load_more')}
              </UIButton>
            )}
          </div>
        </>
      )}
    </m.div>
  );
};
