import { AchievementType } from 'shared/types';

export const ENDPOINTS = {
  CREATE_USER: '/v1/users',
  LOGIN: '/v1/users/login',
  UPDATE_USER: '/v1/users',
  GET_USER: '/v1/users',
  GET__TASKS: '/v1/tasks',
  COMPLETE_TASK: (taskId: string) => `/v1/tasks/${taskId}`,
  GET_REFERALS: (limit: number, offset: number) => `/v1/referrals?limit=${limit}&offset=${offset}`,
  ACCEPT_INVITE: '/v1/referrals',
  REFS_INFO: '/v1/referrals/info',
  START_SESSION: '/v1/game/sessions',
  FINISH_SESSION: (session_id: string) => `/v1/game/sessions/${session_id}`,
  GET_SETTINGS: '/v1/game/settings',
  GET_TASKS: '/v1/tasks',
  GET_ACHIEVEMENTS: (type: AchievementType) => `/v1/achievements?kind=${type}`,
};
