import { ENDPOINTS } from 'shared/constants';
import { GetSettingsResponse, StartSessionResponse } from 'shared/types';
import { httpClientAuthorized } from '../httpClient';

export const startSession = async () => {
  return await httpClientAuthorized.post<StartSessionResponse>(ENDPOINTS.START_SESSION);
};

export const finishSession = async (
  session_id: string,
  loot: number,
  degradation: number,
  failed_loot: number,
) => {
  return await httpClientAuthorized.put<void>(ENDPOINTS.FINISH_SESSION(session_id), {
    loot,
    degradation,
    failed_loot,
  });
};

export const getSettings = async () => {
  return await httpClientAuthorized.get<GetSettingsResponse>(ENDPOINTS.GET_SETTINGS);
};
