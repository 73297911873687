import { useWebApp } from '@vkruglikov/react-telegram-web-app';
import { useLayoutEffect } from 'react';

export const useDisableAppVerticalSwipe = () => {
  const webApp = useWebApp();

  useLayoutEffect(() => {
    webApp?.disableVerticalSwipes();
  }, [webApp]);
};
