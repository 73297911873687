import styles from './style.module.scss';
import { LeagueVariantEntity } from 'shared/types';
import { TOKEN_SHORTCUT } from 'shared/constants';
import { UITypography } from 'shared/ui';
import { useTranslation } from 'react-i18next';
import { LeagueInfoModal } from 'components';
import classNames from 'classnames';
import { formatDonuts } from 'shared/utils';

type LeagueVariantProps = {
  league: LeagueVariantEntity;
  refsCount?: number;
  isInfo?: boolean;
  active?: boolean;
};

export const LeagueVariant = ({ league, refsCount = 0, isInfo, active }: LeagueVariantProps) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(
        styles.root,
        isInfo && styles.infoVariant,
        isInfo && active && styles.active,
      )}
    >
      <div className={styles.container}>
        <img src={league.icon} alt="" width={40} height={40} />

        <div className={classNames(styles.innerContainer, isInfo && styles.alignCenter)}>
          <div className={styles.nameContainer}>
            <UITypography
              variant="H2"
              component="p"
              iconRight={!isInfo && <LeagueInfoModal leagueType={league.type} />}
            >
              {t(league.name)}
            </UITypography>
            <UITypography component="p">{`${league.min_ref}${league.max_ref ? '-' + league.max_ref : ' +'} ${t('friends.friends')}`}</UITypography>
          </div>

          {!isInfo ? (
            <UITypography fontWeight="bold">{`${refsCount}${league.max_ref ? '/' + league.max_ref : ''}`}</UITypography>
          ) : (
            <div className={styles.bblByFriend}>
              <UITypography fontWeight="bold">{`+ ${formatDonuts(league.bbl_by_ref)} ${TOKEN_SHORTCUT}`}</UITypography>
              <UITypography component="p" variant="Menu">
                {t('friends.daily_limit')}
              </UITypography>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
