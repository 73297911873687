import { SUPPORTED_LANGUAGES } from 'shared/constants';
import { AppUser } from 'shared/types';
import { create } from 'zustand';

type GlobalStore = {
  user: AppUser;
  globalLoading: boolean;
  appVisible: boolean;
  checkLimitLoading: boolean;
  refetchAppData: boolean;
  setUser: (user: AppUser) => void;
  setGlobalLoading: (globalLoading: boolean) => void;
  addUserBBL: (bbl: number) => void;
  updateUser: (data: Partial<AppUser>) => void;
  setAppVisibility: (appVisible: boolean) => void;
  setCheckLimitLoading: (checkLimitLoading: boolean) => void;
  setRefetchAppData: (refetchAppData: boolean) => void;
};

export const useGlobalStore = create<GlobalStore>((set) => ({
  user: {
    created_at: '',
    updated_at: '',
    uuid: '',
    tg_user_id: 0,
    username: '',
    first_name: '',
    last_name: '',
    bbl: 0,
    language: SUPPORTED_LANGUAGES.RU,
    finger_size: '39px',
    last_start: '',
    daily_limit: 3600,
    wallet: '',
  },
  globalLoading: true,
  appVisible: true,
  checkLimitLoading: true,
  refetchAppData: false,
  setUser: (user) => set(() => ({ user })),
  setGlobalLoading: (globalLoading) => set(() => ({ globalLoading })),
  addUserBBL: (bbl: number) =>
    set((state) => ({ user: { ...state.user, bbl: state.user.bbl + bbl } })),
  updateUser: (data) => set((state) => ({ user: { ...state.user, ...data } })),
  setAppVisibility: (appVisible) => set(() => ({ appVisible })),
  setCheckLimitLoading: (checkLimitLoading) => set(() => ({ checkLimitLoading })),
  setRefetchAppData: (refetchAppData) => set(() => ({ refetchAppData })),
}));
