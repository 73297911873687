import { ChangeEvent, useState } from 'react';
import { UISlider } from '../slider/slider';
import styles from './style.module.scss';
import { DEFAULT_FINGER_SLIDER_CONFIG } from 'shared/constants';
import classNames from 'classnames';

type FingerSelectProps = {
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
};

export const FingerSelect = ({ onChange, className }: FingerSelectProps) => {
  const [currentFingerSize, setCurrentFingerSize] = useState(DEFAULT_FINGER_SLIDER_CONFIG.min);
  const mergedContainerClassName = classNames(styles.container, className);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentFingerSize(+e.target.value);
    onChange?.(e);
  };

  return (
    <div className={mergedContainerClassName}>
      <div className={styles['outer-ring']}>
        <div
          className={styles.donutBackground}
          style={{
            backgroundImage: `url('/donuts/blue-donut-${DEFAULT_FINGER_SLIDER_CONFIG.min}.png')`,
          }}
        ></div>
        <div
          className={styles.fingerSize}
          style={{ width: currentFingerSize + 'px', height: currentFingerSize + 'px' }}
        >
          <div className={styles['center-dot']}></div>
        </div>
      </div>

      <UISlider
        onChange={handleChange}
        min={DEFAULT_FINGER_SLIDER_CONFIG.min}
        max={DEFAULT_FINGER_SLIDER_CONFIG.max}
        step={DEFAULT_FINGER_SLIDER_CONFIG.step}
        defaultValue={DEFAULT_FINGER_SLIDER_CONFIG.min}
      />
    </div>
  );
};
