import styles from './style.module.scss';
import { UIButton, UITypography } from 'shared/ui';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'shared/constants/routes';
import { getValuesForTimer } from 'shared/utils';
import { useGameStore } from 'shared/store';
import { Swipe } from 'shared/ui/animations';

type DigitSlotProps = {
  value: string;
};

export const SessionCooldownTimer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { gameReady } = useGameStore();

  const onStartSession = () => {
    navigate(ROUTES.SESSION);
  };

  return (
    <div className={styles['session-timer']}>
      {gameReady ? (
        <Swipe from="right" direction="horizontal" style={{ width: '100%' }}>
          <UIButton fullwidth onClick={onStartSession}>
            {t('common.play')}
          </UIButton>
        </Swipe>
      ) : (
        <Swipe from="right" direction="horizontal" style={{ width: '100%' }}>
          <UITypography component="p" fontWeight="bold" style={{ marginBottom: 16 }}>
            {t('main_screen.time_to_next_session')}
          </UITypography>
          <Timer />
        </Swipe>
      )}
    </div>
  );
};

const Timer = () => {
  const { gameCooldown } = useGameStore();
  const { hours, minutes, secondsLeft } = getValuesForTimer(gameCooldown);

  return (
    <div className={styles.digitSlotsContainer}>
      <div className={styles['digit-slot-pair']}>
        <DigitSlot value={hours[0] || '0'} />
        <DigitSlot value={hours[1] || '0'} />
      </div>

      <UITypography variant="H2">:</UITypography>

      <div className={styles['digit-slot-pair']}>
        <DigitSlot value={minutes[0] || '0'} />
        <DigitSlot value={minutes[1] || '0'} />
      </div>

      <UITypography variant="H2">:</UITypography>

      <div className={styles['digit-slot-pair']}>
        <DigitSlot value={secondsLeft[0] || '0'} />
        <DigitSlot value={secondsLeft[1] || '0'} />
      </div>
    </div>
  );
};

const DigitSlot = ({ value }: DigitSlotProps) => {
  return (
    <div className={styles['digit-slot']}>
      <UITypography variant="H2">{value}</UITypography>
    </div>
  );
};
