import { m } from 'framer-motion';
import styles from './style.module.scss';
import { UITypography } from '../typography/typography';
import { SELECT_ANIMATION_VARIANTS } from 'shared/constants/animation-variants';
import { SelectOption } from './select';

type MenuListProps = {
  options: SelectOption[];
  isOpen: boolean;
  dropdownTop: number;
  dropdownLeft: number;
  dropdownRight: number;
  dropdownWidth: number;
  handleItemClick: (item: SelectOption) => void;
};

export const MenuList = ({
  dropdownLeft,
  dropdownRight,
  dropdownTop,
  dropdownWidth,
  options,
  handleItemClick,
  isOpen,
}: MenuListProps) => (
  <m.ul
    className={styles['dropdown-list']}
    style={{
      top: dropdownTop,
      left: dropdownLeft,
      right: dropdownRight,
      width: dropdownWidth,
    }}
    animate={isOpen ? 'open' : 'closed'}
    initial="closed"
    exit="closed"
    variants={SELECT_ANIMATION_VARIANTS}
  >
    {options.map((item) => (
      <li
        key={item.value}
        onClick={() => handleItemClick(item)}
        className={styles['dropdown-item']}
      >
        <UITypography variant="Body" color="black">
          {item.label}
        </UITypography>
      </li>
    ))}
  </m.ul>
);
