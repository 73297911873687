import styles from './style.module.scss';

type SpinnerProps = {
  size?: number;
  borderWidth?: number;
};

export const Spinner = ({ size = 50, borderWidth = 7 }: SpinnerProps) => {
  return <div className={styles.spinner} style={{ width: size, height: size, borderWidth }} />;
};
