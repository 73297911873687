import styles from './style.module.scss';
import { m, useAnimate, usePresence } from 'framer-motion';
import { memo } from 'react';
import { FLY_SIZE, PRELOAD_GAME_ASSETS } from 'shared/constants';
import { useGameStore } from 'shared/store';
import { FlyEntity } from 'shared/types';

type FlyProps = FlyEntity & {
  onClick?: (id: string) => void;
};

export const Fly = memo(({ startX, startY, onClick, id, endX, endY, rotation }: FlyProps) => {
  const [scope, animate] = useAnimate();
  const [isPresent, safeToRemove] = usePresence();
  const { settings } = useGameStore();

  const handleClick = async () => {
    onClick?.(id);
    scope.current.style.pointerEvents = 'none';
    if (isPresent) {
      await animate(scope.current, { opacity: 0 }, { duration: 0.1 });
    } else {
      safeToRemove();
    }
  };

  return (
    <m.div
      initial={{ y: startY, x: startX }}
      onTouchStart={handleClick}
      animate={{ y: endY, x: endX }}
      transition={{ duration: settings.fall_bee_speed, ease: 'linear' }}
      ref={scope}
      className={styles.root}
    >
      <img
        src={PRELOAD_GAME_ASSETS.flyImage}
        alt=""
        className={styles['fly-image']}
        width={FLY_SIZE}
        height={FLY_SIZE}
        style={{ transform: `rotate(${rotation}deg)` }}
      />
    </m.div>
  );
});
