import { UITypography } from 'shared/ui';
import styles from './style.module.scss';
import { TimerReturnValue } from 'shared/types';
import variables from 'shared/ui/theme/variables.scss';

type Props = {
  timerValue: TimerReturnValue;
};

export const GameTimer = ({ timerValue }: Props) => {
  const danger = timerValue.seconds <= 10;

  return (
    <div
      className={styles.timer}
      style={{
        border: `2px solid ${danger ? variables.colorRose : 'transparent'}`,
        backgroundColor: danger ? variables.colorRose20 : variables.colorWhite20,
        color: danger ? variables.colorRose : variables.colorWhite,
      }}
    >
      <UITypography
        color={'inherit'}
        variant="Button1"
      >{`${timerValue.gameTime.minutes}:${timerValue.gameTime.secondsLeft}`}</UITypography>
    </div>
  );
};
