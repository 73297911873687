import styles from './style.module.scss';
import { UITypography } from 'shared/ui';
import stars2Image from 'assets/images/stars2.png';
import bobeWithDonutsImage from 'assets/images/bobe-with-donuts.png';

export const BakeryStats = () => {
  return (
    <div className={styles.root}>
      <img src={stars2Image} alt="" className={styles.stars} />
      <UITypography variant="H2" className={styles.text}>
        3.6K DON = 1 BBL
      </UITypography>
      <img
        src={bobeWithDonutsImage}
        alt=""
        className={styles.bobeWithDonuts}
        width={146}
        height={165}
      />
    </div>
  );
};
