import { forwardRef, InputHTMLAttributes } from 'react';
import styles from './style.module.scss';
import classNames from 'classnames';

type CommonInputProps = InputHTMLAttributes<HTMLInputElement>;
type UIinputVariant = 'primary' | 'secondary';

type UIInputProps = Omit<CommonInputProps, 'size'> & {
  variant?: UIinputVariant;
  fullwidth?: boolean;
};

export const UIInput = forwardRef<HTMLInputElement, UIInputProps>(
  ({ className, variant = 'primary', ...other }, ref) => {
    const mergedClasName = classNames(styles[variant] || '', className);

    return <input {...other} ref={ref} className={mergedClasName} />;
  },
);
