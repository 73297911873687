import { SESSION_STORAGE_KEYS } from 'shared/constants';

export const setToken = (token: string) => {
  try {
    sessionStorage.setItem(SESSION_STORAGE_KEYS.ACESS_TOKEN, token);
  } catch (error) {
    console.log(error);
  }
};
export const getToken = () => {
  try {
    return sessionStorage.getItem(SESSION_STORAGE_KEYS.ACESS_TOKEN) || null;
  } catch (error) {
    console.log(error);
  }
};
