import styles from './style.module.scss';
import { Fly, FreezeOverlay, Donut } from 'components';
import { useDonuts } from './hooks/use-donuts';
import { useFlies } from './hooks/use-flies';

type Props = {
  stopGame: (limitReached?: boolean) => Promise<void>;
};

export const GameContainer = ({ stopGame }: Props) => {
  const { donuts, handleDonutClick, handleDonutAnimationEnd } = useDonuts({ stopGame });
  const { flies, freezeCooldown, onFlyClick } = useFlies();

  return (
    <div className={styles.container}>
      <div className={styles['game-container']}>
        {donuts.map((donut) => (
          <Donut
            {...donut}
            key={donut.id}
            onClick={handleDonutClick}
            onAnimationComplete={handleDonutAnimationEnd}
          />
        ))}
        {flies.map((fly) => (
          <Fly {...fly} key={fly.id} onClick={onFlyClick} />
        ))}
      </div>
      <FreezeOverlay freezeCooldown={freezeCooldown} />
    </div>
  );
};
