import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import { UIModal, UIModalProps, UITypography } from 'shared/ui';
import doneIcon from 'assets/icons/done.png';

type InviteLinkCopiedModalProps = Omit<UIModalProps, 'children'>;

export const InviteLinkCopiedModal = ({ visible, onClose }: InviteLinkCopiedModalProps) => {
  const { t } = useTranslation();

  return (
    <UIModal visible={visible} onClose={onClose} detent="content-height">
      <div>
        <img src={doneIcon} width={80} height={80} />
        <UITypography className={styles.title} variant="H1" component="p">
          {t('friends.link_copied')}
        </UITypography>
        <UITypography component="p">{t('friends.send_to_friends')}</UITypography>
      </div>
    </UIModal>
  );
};
