import { useState } from 'react';
import { UIModal } from 'shared/ui/components/modal/modal';
import styles from './style.module.scss';
import { ReactComponent as InfoCircleActive } from 'assets/icons/info-circle-active.svg';
import { ReactComponent as InfoCircle } from 'assets/icons/info-circle.svg';
import { UITypography } from 'shared/ui/components/typography/typography';
import { useTranslation } from 'react-i18next';

export const AboutUsModal = () => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <div className={styles['modal-trigger']} onClick={() => setOpen(true)}>
        <InfoCircle />
        <UITypography variant="Button1">{t('common.about_project')}</UITypography>
      </div>
      <UIModal visible={open} onClose={() => setOpen(false)} detent="content-height">
        <div className={styles['modal-content']}>
          <InfoCircleActive />
          <div className={styles['text-container']}>
            <UITypography component="p">
              {t('choose_language_screen.about_us_modal.text1')}
            </UITypography>
            <UITypography component="p">
              {t('choose_language_screen.about_us_modal.text2')}
            </UITypography>
            <UITypography component="p">
              {t('choose_language_screen.about_us_modal.text3')}
            </UITypography>
          </div>
        </div>
      </UIModal>
    </>
  );
};
