type Props<ResultType> = {
  promise: PromiseSettledResult<ResultType>;
  callback: (result: ResultType) => void;
};

export const handleSettledPromise = <ResultType>({ promise, callback }: Props<ResultType>) => {
  if (promise.status === 'fulfilled') {
    callback(promise.value);
  } else {
    console.log(promise.reason);
  }
};
