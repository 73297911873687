import { useEffect, useRef, useState } from 'react';
import { useGameStore } from 'shared/store';
import { getValuesForTimer } from 'shared/utils';

type useGameTimerProps = {
  stopGame: () => Promise<void>;
  startValue: number;
};

export const useGameTimer = ({ stopGame, startValue }: useGameTimerProps) => {
  const [seconds, setSeconds] = useState(startValue);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const {
    increaseDonutsFallSpeedInterval,
    settings,
    increaseCurrentDonutFallSpeed,
    setCurrentDonutFallSpeed,
  } = useGameStore();

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setSeconds((prev) => prev - 1);
    }, 1000);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const handleTimer = async () => {
      if (seconds !== startValue && seconds % increaseDonutsFallSpeedInterval === 0) {
        increaseCurrentDonutFallSpeed(0.07);
      }

      if (seconds < 1) {
        intervalRef.current && clearInterval(intervalRef.current);
        await stopGame();
        setCurrentDonutFallSpeed(settings.fall_donuts_speed);
      }
    };
    handleTimer();
  }, [seconds]);

  return { gameTime: getValuesForTimer(seconds), seconds };
};
