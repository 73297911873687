import { useCloudStorage } from '@vkruglikov/react-telegram-web-app';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { finishSession, startSession } from 'shared/api';
import { CS_KEYS, ROUTES } from 'shared/constants';
import { useGameStore, useGlobalStore } from 'shared/store';
import { DayLimitDonut } from 'shared/types';
import { calculateEarnedCurrency } from 'shared/utils';

export function useGame() {
  const navigate = useNavigate();
  const {
    setGameActive,
    setCurrentDonutsCount,
    currentDonutsCount,
    setSessionId,
    sessionId,
    setTodayCurrentLimit,
    catchedBees,
    failedDonuts,
    setFrozen,
    setGameReady,
  } = useGameStore();
  const { setItem, getItem } = useCloudStorage();
  const { addUserBBL, user } = useGlobalStore();

  const startGame = useCallback(async () => {
    try {
      setCurrentDonutsCount(0);
      setGameActive(true);

      const res = await startSession();
      setSessionId(res.data.uuid);
      await setItem(CS_KEYS.LAST_START, `${Date.now()}`);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const changeTodayLimit = async (earnedCurrency: number) => {
    await setItem(
      CS_KEYS.TODAY_DONUTS_COUNT,
      JSON.stringify({
        timestamp: Date.now(),
        donuts: earnedCurrency,
      }),
    );
    setTodayCurrentLimit(earnedCurrency);
  };

  const leaveSession = (limitReached: boolean) => {
    if (limitReached) {
      navigate(ROUTES.LIMIT_REACHED, {
        replace: true,
      });
    } else {
      navigate(ROUTES.CONGRATULATIONS, {
        replace: true,
      });
    }
  };

  const stopGame = useCallback(
    async (limitReached = false) => {
      try {
        setGameActive(false);
        setGameReady(false);
        const limit = await getItem(CS_KEYS.TODAY_DONUTS_COUNT);

        const earnedDonuts = calculateEarnedCurrency({
          dailyLimit: user.daily_limit,
          earnedCurrency: currentDonutsCount,
          currentDailyLimit: limit ? +JSON.parse(limit).donuts : 0,
          limitReached: limitReached,
        });

        if (limit) {
          const parsedLimit: DayLimitDonut = JSON.parse(limit);
          const todayEarnedCurrency = limitReached
            ? Math.floor(user.daily_limit)
            : earnedDonuts + +parsedLimit.donuts;
          await changeTodayLimit(todayEarnedCurrency);
        } else {
          await changeTodayLimit(earnedDonuts);
        }

        await finishSession(sessionId, earnedDonuts, catchedBees, failedDonuts);
        addUserBBL(earnedDonuts);
        setCurrentDonutsCount(earnedDonuts);
        setSessionId('');
        setFrozen(false);
      } catch (error) {
        console.log(error);
      } finally {
        leaveSession(limitReached);
      }
    },
    [currentDonutsCount, sessionId, catchedBees, failedDonuts, user],
  );

  useEffect(() => {
    startGame();
  }, []);

  return {
    startGame,
    stopGame,
  };
}
