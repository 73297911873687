import { LoadingProvider } from 'components';
import { Outlet } from 'react-router-dom';
import { useGameStore, useGlobalStore } from 'shared/store';

export const GlobalLoadingLayout = () => {
  const { globalLoading, checkLimitLoading } = useGlobalStore();
  const { cooldownTimerLoading } = useGameStore();

  const loading = globalLoading || cooldownTimerLoading || checkLimitLoading;

  return (
    <LoadingProvider loading={loading}>
      <Outlet />
    </LoadingProvider>
  );
};
