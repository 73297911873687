import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import { UITypography } from 'shared/ui';
import bronzeCoinIcon from 'assets/images/bronze-coin.png';

export const NoLeague = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.noLeague}>
      <img src={bronzeCoinIcon} alt="" width={40} height={40} />
      <UITypography variant="H2">{t('friends.has_no_league')}</UITypography>
    </div>
  );
};
