import { LoadingContainer } from './loading-container';

type LoadingProviderProps = {
  loading: boolean;
  children: React.ReactNode;
};

export const LoadingProvider = ({ loading, children }: LoadingProviderProps) => {
  return loading ? <LoadingContainer /> : children;
};
