import { UITypography } from 'shared/ui';
import styles from './style.module.scss';
import { AchievementEntity } from 'shared/types';
import { ReactComponent as TickCircleIcon } from 'assets/icons/tick-circle.svg';
import { formatDonuts } from 'shared/utils';

type AchievementProps = AchievementEntity;

export const Achievement = ({
  title,
  icon,
  description,
  current_value,
  value,
  type,
}: AchievementProps) => {
  const isStatic = type === 'static';

  return (
    <div className={styles.root}>
      <img src={icon} alt="" width={40} height={40} />
      <div className={styles.info}>
        <div className={styles.text}>
          <UITypography variant="H2" component="p">
            {title}
          </UITypography>
          <UITypography component="p">{description}</UITypography>
        </div>

        <div className={styles.valueContainer}>
          {!isStatic && current_value >= value && (
            <TickCircleIcon className={styles.tickCircleIcon} />
          )}
          <UITypography>{formatDonuts(current_value)}</UITypography>
        </div>
      </div>
    </div>
  );
};
