type ValuesWithChance = { value: number; chance: number }[];

const lerp = (min: number, max: number, value: number) => (1 - value) * min + value * max;

export const getRandomValueWithChance = (items: ValuesWithChance) => {
  const total = items.reduce((accumulator, item) => accumulator + item.chance, 0);
  const chance = lerp(0, total, Math.random());

  let current = 0;
  const selectedItem = items.find((item) => {
    current += item.chance;
    return current > chance;
  });

  return selectedItem ? selectedItem.value : items[items.length - 1].value;
};
