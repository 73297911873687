import { useCloudStorage } from '@vkruglikov/react-telegram-web-app';
import { useRef, useCallback, useEffect } from 'react';
import { CS_KEYS, DAY_IN_MS } from 'shared/constants';
import { useGameStore } from 'shared/store';

export const useGameCooldownTimer = () => {
  const {
    settings,
    gameActive,
    gameReady,
    setGameReady,
    setGameCooldown,
    setTodayCurrentLimit,
    setCooldownTimerLoading,
  } = useGameStore();
  const { getItem, removeItem } = useCloudStorage();

  const interval = useRef<NodeJS.Timeout | null>(null);

  const calculateTimeRemaining = useCallback(
    async (lastStart: string, dayCooldown: string) => {
      let remainingTime = 0;

      //if max limit today
      if (dayCooldown) {
        remainingTime = +lastStart + DAY_IN_MS - Date.now();

        if (remainingTime > 0) {
          setGameCooldown(remainingTime / 1000);
        } else {
          interval.current && clearInterval(interval.current);
          setGameCooldown(0);
          setGameReady(true);
          await removeItem(CS_KEYS.DAY_COOLDOWN);
          await removeItem(CS_KEYS.TODAY_DONUTS_COUNT);
          setTodayCurrentLimit(0);
        }
      } else {
        //if NO max limit today
        remainingTime = +lastStart + settings.waiting_time * 1000 - Date.now();

        if (remainingTime > 0) {
          setGameCooldown(remainingTime / 1000);
        } else {
          interval.current && clearInterval(interval.current);
          setGameCooldown(0);
          setGameReady(true);
        }
      }
    },
    [settings.waiting_time],
  );

  const initGameCooldownTimer = async () => {
    try {
      const lastStart = await getItem(CS_KEYS.LAST_START);
      const dayCooldown = await getItem(CS_KEYS.DAY_COOLDOWN);

      await new Promise<void>((resolve) => {
        interval.current = setInterval(() => {
          calculateTimeRemaining(lastStart, dayCooldown);
          resolve();
        }, 1000);
      });
    } catch (error) {
      console.log(error);
    } finally {
      setCooldownTimerLoading(false);
    }
  };

  useEffect(() => {
    if (!gameActive && !gameReady) {
      initGameCooldownTimer();
    } else {
      interval.current && clearInterval(interval.current);
    }

    return () => {
      interval.current && clearInterval(interval.current);
    };
  }, [gameActive, gameReady]);
};
