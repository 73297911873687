import styles from './style.module.scss';
import { UIButton, UITypography } from 'shared/ui';
import { useGlobalStore } from 'shared/store';
import { useWebApp } from '@vkruglikov/react-telegram-web-app';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectWalletModal } from 'components';
import { ReactComponent as TickCircleIcon } from 'assets/icons/tick-circle.svg';
import walletImage from 'assets/images/wallet-front-color.png';

export const Wallet = () => {
  const { t } = useTranslation();
  const { user } = useGlobalStore();
  const WebApp = useWebApp();
  const isAndroid = WebApp.platform === 'android';

  // const [visibleWalletConnectedModal, setVisibleWalletConnectedModal] = useState(false);
  const [visibleConnectWalletModal, setVisibleConnectWalletModal] = useState(false);

  // TODO: replace URLS when customer provide them
  const connectLink = ``;

  const openConnectLink = () => {
    setVisibleConnectWalletModal(true);

    if (!isAndroid) {
      WebApp.openLink(connectLink);
    }
  };

  return (
    <div>
      {user.wallet ? (
        <div className={styles.walletConnectedRoot}>
          <div className={styles.walletConnectedContent}>
            <img src={walletImage} alt="" width={40} height={40} style={{ flexShrink: 0 }} />
            <div className={styles.walletInfo}>
              <UITypography component="p" variant="H2" style={{ flexShrink: 0 }}>
                {`${t('settings.wallet')}:`}
              </UITypography>

              <UITypography>
                {user.wallet.slice(0, 4) + '****' + user.wallet.slice(-4)}
              </UITypography>
            </div>
          </div>

          <TickCircleIcon className={styles.tickCircleIcon} />
        </div>
      ) : (
        <UIButton fullwidth onClick={openConnectLink}>
          {t('settings.connect_wallet_modal.connect_wallet')}
        </UIButton>
      )}

      {/* <WalletConnectedModal
        visible={visibleWalletConnectedModal}
        onClose={() => setVisibleWalletConnectedModal(false)}
      /> */}

      <ConnectWalletModal
        visible={visibleConnectWalletModal}
        onClose={() => setVisibleConnectWalletModal(false)}
        link={connectLink}
        isAndroid={isAndroid}
      />
    </div>
  );
};
