import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import { UITypography } from 'shared/ui';
import { ReactComponent as Ranking2Icon } from 'assets/icons/ranking-2.svg';
import { LeagueVariant } from '../league-variant/league-variant';
import { NoLeague } from './no-league';
import { useReferralsStore } from 'shared/store';
import { LEAGUE_VARIANTS } from 'shared/constants';

export const CurrentLeague = () => {
  const { t } = useTranslation();
  const { totalRefs, league } = useReferralsStore();

  return (
    <div className={styles.root}>
      <UITypography iconLeft={<Ranking2Icon />}>{t('friends.actual_league')}</UITypography>

      {!league ? (
        <NoLeague />
      ) : (
        <LeagueVariant league={LEAGUE_VARIANTS[league]} refsCount={totalRefs} />
      )}
    </div>
  );
};
