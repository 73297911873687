import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { COMMON_PRELOAD_ASSETS, ROUTES } from 'shared/constants';

const SESSION_BG_ROUTES = [ROUTES.BAKERY, ROUTES.SESSION, ROUTES.MARKET];
const MAIN_SCREEN_BG_ROUTES = [ROUTES.MAIN];

export const useAppBackground = () => {
  const location = useLocation();

  useLayoutEffect(() => {
    if (SESSION_BG_ROUTES.includes(location.pathname)) {
      document.body.style.backgroundImage = `url(${COMMON_PRELOAD_ASSETS.sessionBgImage})`;
      return;
    }

    if (MAIN_SCREEN_BG_ROUTES.includes(location.pathname)) {
      document.body.style.backgroundImage = `url(${COMMON_PRELOAD_ASSETS.mainScreenBgImage})`;
      return;
    }

    document.body.style.backgroundImage = `url(${COMMON_PRELOAD_ASSETS.defaultBgImage})`;
  }, [location.pathname]);
};
