import styles from './style.module.scss';
import { UserSizeDonut } from './user-size-donut';
import { SessionCooldownTimer } from './session-cooldown-timer';
import { Boosters } from './boosters';
import { Limit } from './limit';
import { Swipe } from 'shared/ui/animations';

export const MainScreenModule = () => {
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <Swipe direction="vertical" from="top">
          <Boosters />
        </Swipe>

        <UserSizeDonut />

        <SessionCooldownTimer />

        <Swipe direction="horizontal" from="left">
          <Limit />
        </Swipe>
      </div>
    </div>
  );
};
