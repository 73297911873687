import styles from './style.module.scss';
import { UIButton, UITypography } from 'shared/ui';
import { useTranslation } from 'react-i18next';
import { BakeryStats } from './bakery-stats/bakery-stats';
import { useGameStore, useGlobalStore } from 'shared/store';
import { ChangeEvent, useEffect, useState } from 'react';
import { Swipe } from 'shared/ui/animations';
import { ReactComponent as DollarCircleIcon } from 'assets/icons/dollar-circle.svg';

export const BakeryModule = () => {
  const { t } = useTranslation();
  const { user } = useGlobalStore();
  const [bblValue, setbblValue] = useState('0');
  const [usdtAmount, setUsdtAmount] = useState('0');
  const { settings } = useGameStore();

  const incorrectValue = !bblValue || +bblValue < 1 || +bblValue > user.bbl;

  const sendToBakery = () => {
    if (incorrectValue) return;
    // TODO: redirect to outside bakery and send url param with bblValue
    return null;
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setbblValue(e.target.value);
    setUsdtAmount((user.bbl * settings.bbl_rate).toFixed(3));
  };

  useEffect(() => {
    setbblValue(`${user.bbl}`);
    setUsdtAmount((user.bbl * settings.bbl_rate).toFixed(3));
  }, [user.bbl]);

  return (
    <div className={styles.root}>
      <Swipe direction="vertical" from="top">
        <UITypography iconLeft={<DollarCircleIcon />} className={styles.subTitle}>
          {t('bakery.bakery_stats')}
        </UITypography>

        <BakeryStats />

        {/* TODO: uncomment when ready  */}
        {/* <PredictedAmount bblCount={bblValue} usdtAmount={usdtAmount} onChange={onChange} /> */}
      </Swipe>

      <Swipe direction="horizontal" from="right">
        <UIButton fullwidth onClick={sendToBakery} disabled>
          {t('bakery.send_to_bakery')}
        </UIButton>
      </Swipe>
    </div>
  );
};
