import { useFetchRefs } from 'shared/api/hooks/use-fetch-refs';
import styles from './style.module.scss';
import { CurrentLeague, InviteFriendsButton, ReferralsList } from 'components';
import { Swipe } from 'shared/ui/animations';

export const FriendsModule = () => {
  const { loading } = useFetchRefs();

  return (
    <div className={styles.root}>
      {!loading && (
        <Swipe direction="vertical" from="top">
          <CurrentLeague />
        </Swipe>
      )}

      <ReferralsList initialLoading={loading} />

      <Swipe direction="horizontal" from="right">
        <InviteFriendsButton />
      </Swipe>
    </div>
  );
};
