import styles from './style.module.scss';
import { UITypography } from 'shared/ui';
import { Spinner } from './spinner';
import { BobeHero } from 'components/bobe-hero/bobe-hero';
import { memo } from 'react';

type LoadingContainerProps = {
  isGlobal?: boolean;
};

export const LoadingContainer = memo(({ isGlobal = true }: LoadingContainerProps) => {
  return (
    <div className={styles.root} style={{ height: isGlobal ? '100vh' : '100%' }}>
      {isGlobal && (
        <>
          <BobeHero />
          <UITypography>Loading...</UITypography>
        </>
      )}

      <Spinner />
    </div>
  );
});
