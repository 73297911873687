import styles from './style.module.scss';
import { t } from 'i18next';
import { useState, useRef, ChangeEvent, useCallback, FocusEventHandler } from 'react';
import { UITypography, UIInput, UIButton } from 'shared/ui';

type ContentProps = {
  onClose: () => void;
  onFocus: FocusEventHandler;
  onBlur: FocusEventHandler;
};

export const Content = ({ onClose, onFocus, onBlur }: ContentProps) => {
  const [value, setValue] = useState('');
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const getMoreBBL = useCallback(() => {
    if (+value < 1 || isNaN(+value)) {
      alert('Incorrect value');
      return;
    }
    // TODO: redirect to link and pass query param with value
    onClose();
    setValue('');
  }, [value, onClose]);

  return (
    <div className={styles.content}>
      <UITypography variant="H1">{t('progress.input_bbl_count')}</UITypography>

      <UIInput
        ref={inputRef}
        variant="secondary"
        value={value}
        onChange={handleChange}
        className={styles.bblInput}
        type="number"
        inputMode="numeric"
        pattern="[0-9]*"
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <UIButton onClick={getMoreBBL} fullwidth>
        {t('progress.get_bbl')}
      </UIButton>
    </div>
  );
};
