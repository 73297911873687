import { useGlobalStore } from 'shared/store';

export const useInviteLink = () => {
  const { user } = useGlobalStore();

  const inviteLink =
    process.env.REACT_APP_INVITE_LINK_BASE || 'https://t.me/BOBEDevBot/app?startapp=appid_';

  return `${inviteLink}${user.tg_user_id}
Join the BOBE game, earn BBL and convert it to crypto!`;
};
