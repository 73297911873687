import styles from './style.module.scss';
import { UIButton, UITypography } from 'shared/ui';
import { useInitData } from '@vkruglikov/react-telegram-web-app';
import { useGlobalStore } from 'shared/store';
import { useTranslation } from 'react-i18next';
import { TOKEN_SHORTCUT } from 'shared/constants';
import { formatDonuts } from 'shared/utils';

type UserWidgetProps = {
  username?: string;
  avatar?: string;
  isReferral?: boolean;
  isApproved?: boolean;
  bblCount?: number;
};

export const UserWidget = ({
  username,
  avatar,
  isReferral,
  isApproved,
  bblCount,
}: UserWidgetProps) => {
  const { user } = useGlobalStore();
  const [initDataUnsafe] = useInitData();
  const tgUser = initDataUnsafe?.user;

  const _username = username ? username : user.username || user.tg_user_id;
  const _photo = (avatar || tgUser?.photo_url || '/bobe-avatar-placeholder.png') as string;

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        {!isReferral && (
          <div className={styles.avatar}>
            <img src={_photo} alt="" className={styles.avatarImage} />
          </div>
        )}

        <UITypography fontWeight="bold" className={styles.username}>
          {_username}
        </UITypography>
      </div>

      {isReferral && <BblCount isApproved={isApproved} bblCount={bblCount} />}
    </div>
  );
};

const BblCount = ({ isApproved, bblCount }: UserWidgetProps) => {
  const { t } = useTranslation();
  return !isApproved ? (
    <UITypography fontWeight="bold">{`+${formatDonuts(bblCount || 0)} ${TOKEN_SHORTCUT}`}</UITypography>
  ) : (
    <UIButton disabled size="small2" fontVariant="Button2">
      {t('common.pending')}
    </UIButton>
  );
};
