import { useCloudStorage, useInitData } from '@vkruglikov/react-telegram-web-app';
import { AxiosResponse } from 'axios';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { acceptInvite, getUser, getSettings, getTasks, getAchievements } from 'shared/api';
import { CS_KEYS, ROUTES, SUPPORTED_LANGUAGES } from 'shared/constants';
import { appSessionStorage } from 'shared/service';
import { useGameStore, useGlobalStore } from 'shared/store';
import { useProgressStore } from 'shared/store/progress';
import { AchievementEntity, GetSettingsResponse, TaskEntity } from 'shared/types';
import { handleSettledPromise } from 'shared/utils';

export const useAuthAndFetchAppData = () => {
  const navigate = useNavigate();
  const { setUser, setGlobalLoading, refetchAppData } = useGlobalStore();
  const { setSettings, setCurrentDonutFallSpeed } = useGameStore();
  const [initDataUnsafe, initData] = useInitData();
  const { i18n } = useTranslation();
  const { setStaticAchievements, setTasks, setDynamicAchievements } = useProgressStore();
  const { getItem } = useCloudStorage();

  const fetchUser = async () => {
    try {
      const res = await getUser();
      setUser(res.data);
      return res.data;
    } catch (error) {
      navigate(ROUTES.CHOOSE_LANGUAGE, { replace: true });
      return null;
    }
  };

  const setAppLanguage = async (language: SUPPORTED_LANGUAGES | null) => {
    if (language) {
      i18n.changeLanguage(language);
      return;
    }
    const userLanguage = await getItem(CS_KEYS.LANGUAGE);
    if (userLanguage) {
      i18n.changeLanguage(userLanguage as SUPPORTED_LANGUAGES);
      return;
    }
  };

  const fetchAppData = async () => {
    try {
      const [gameSettingsRes, tasksRes, staticAchievementsRes, dynamicAchievementsRes] =
        await Promise.allSettled([
          getSettings(),
          getTasks(),
          getAchievements('static'),
          getAchievements('dynamic'),
        ]);

      handleSettledPromise<AxiosResponse<GetSettingsResponse>>({
        promise: gameSettingsRes,
        callback: (result) => {
          setSettings(result.data);
          setCurrentDonutFallSpeed(result.data.fall_donuts_speed);
        },
      });

      // TODO: delete replace and map after it's ready on backend
      const replaceUrl = (url: string) => {
        if (url?.startsWith('/app')) {
          return url.replace('/app', 'https://api.bobe.kuki.agency');
        }
        return url;
      };

      handleSettledPromise<AxiosResponse<TaskEntity[]>>({
        promise: tasksRes,
        callback: (result) => {
          setTasks(result.data.map((item) => ({ ...item, icon: replaceUrl(item.icon) })));
        },
      });
      handleSettledPromise<AxiosResponse<AchievementEntity[]>>({
        promise: staticAchievementsRes,
        callback: (result) => {
          setStaticAchievements(
            result.data.map((item) => ({ ...item, icon: replaceUrl(item.icon) })),
          );
        },
      });
      handleSettledPromise<AxiosResponse<AchievementEntity[]>>({
        promise: dynamicAchievementsRes,
        callback: (result) => {
          setDynamicAchievements(
            result.data.map((item) => ({ ...item, icon: replaceUrl(item.icon) })),
          );
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const checkInvite = async () => {
    if (initDataUnsafe?.start_param) {
      const match = initDataUnsafe?.start_param?.match(/appid_(\d+)/);
      const result = match ? match[1] : null;

      if (result) {
        await acceptInvite(Number(result));
      }
    }
  };

  useEffect(() => {
    const prepareAppData = async () => {
      try {
        if (initData) {
          appSessionStorage.setToken(initData);
        } else return;

        const user = await fetchUser();
        if (!user) return;

        await setAppLanguage(user.language);
        await fetchAppData();
      } finally {
        await checkInvite();
        setGlobalLoading(false);
      }
    };
    prepareAppData();

    //@eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initData, refetchAppData]);
};
