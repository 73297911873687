import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import { UITypography } from 'shared/ui';
import { ReactComponent as TaskIcon } from 'assets/icons/task-square.svg';
import { Achievement, GetMoreBBLModal, Task, TaskModal } from 'components';
import { useProgressStore } from 'shared/store/progress';
import { ReactComponent as MedalStarIcon } from 'assets/icons/medal-star.svg';
import { ReactComponent as MagicStarIcon } from 'assets/icons/magic-star.svg';
import { TaskEntity } from 'shared/types';
import { useState } from 'react';
import { Swipe } from 'shared/ui/animations';
import { m } from 'framer-motion';
import { LEFT_SWIPE_CHILD_VARIANTS, LEFT_SWIPE_PARENT_VARIANTS } from 'shared/constants';

export const ProgressModule = () => {
  const { t } = useTranslation();
  const { tasks, staticAchievements, dynamicAchievements } = useProgressStore();
  const [openedTask, setOpenedTask] = useState<TaskEntity | null>(null);

  const onTaskClick = (task: TaskEntity) => setOpenedTask(task);

  const onTaskModalClose = () => setOpenedTask(null);

  return (
    <div className={styles.root}>
      <m.div
        className={styles.scrollContainer}
        variants={LEFT_SWIPE_PARENT_VARIANTS}
        initial="hidden"
        animate="visible"
      >
        <Swipe variants={LEFT_SWIPE_CHILD_VARIANTS}>
          <UITypography component="h2" variant="H1">
            {t('progress.tasks')}
          </UITypography>

          <div className={styles.activeTasksList}>
            <UITypography
              iconLeft={<TaskIcon />}
            >{`${t('progress.active_tasks')} (${tasks.length})`}</UITypography>

            {tasks.map((task) => (
              <Task key={task.id} {...task} onClick={onTaskClick} />
            ))}
          </div>
        </Swipe>
        <Swipe variants={LEFT_SWIPE_CHILD_VARIANTS}>
          <UITypography component="h2" variant="H1">
            {t('progress.achievements')}
          </UITypography>

          <div className={styles.staticAchievementsList}>
            <UITypography
              iconLeft={<MedalStarIcon />}
            >{`${t('progress.static_achievements')} (${staticAchievements.length})`}</UITypography>
            {staticAchievements.map((staticAchievement) => (
              <Achievement key={staticAchievement.title} {...staticAchievement} />
            ))}
          </div>

          <div className={styles.staticAchievementsList}>
            <UITypography
              iconLeft={<MagicStarIcon />}
            >{`${t('progress.dynamic_achievements')} (${dynamicAchievements.length})`}</UITypography>
            {dynamicAchievements.map((dynamicAchievement) => (
              <Achievement key={dynamicAchievement.title} {...dynamicAchievement} />
            ))}
          </div>
        </Swipe>
      </m.div>

      <GetMoreBBLModal />

      <TaskModal
        visible={!!openedTask}
        onClose={onTaskModalClose}
        title={openedTask?.title || ''}
        href={openedTask?.assignment || ''}
        icon={openedTask?.icon || ''}
        taskId={openedTask?.id || ''}
        status={openedTask?.status || 'to_do'}
        description={openedTask?.description || ''}
        award={openedTask?.award_value || 0}
        awardKind={openedTask?.award_kind || 'bbl'}
      />
    </div>
  );
};
