import { ENDPOINTS } from 'shared/constants';
import { httpClientAuthorized } from '../httpClient';
import { GetRefsInfoResponse, GetRefsResponse } from 'shared/types';

export const getRefs = async (limit: number, offset: number) => {
  return await httpClientAuthorized.get<GetRefsResponse>(ENDPOINTS.GET_REFERALS(limit, offset));
};

export const acceptInvite = async (referrer_id: number) => {
  return await httpClientAuthorized.post<void>(ENDPOINTS.ACCEPT_INVITE, {
    referrer_id,
  });
};

export const getRefsInfo = async () => {
  return await httpClientAuthorized.get<GetRefsInfoResponse>(ENDPOINTS.REFS_INFO);
};
