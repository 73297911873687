import { LoadingProvider } from 'components';
import { useAuthAndFetchAppData } from 'shared/api';
import { useCheckDailyLimit, useGameCooldownTimer } from 'shared/hooks';
import { useGlobalStore } from 'shared/store';

type AuthRouteProps = {
  children: React.ReactElement;
};

export const AuthRoute = ({ children }: AuthRouteProps) => {
  const { globalLoading } = useGlobalStore();

  useCheckDailyLimit();
  useAuthAndFetchAppData();
  useGameCooldownTimer();

  return <LoadingProvider loading={globalLoading}>{children}</LoadingProvider>;
};
