import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { SUPPORTED_LANGUAGES } from 'shared/constants/common';
import translationEN from 'shared/locales/EN.json';
import translationRU from 'shared/locales/RU.json';

interface II18NProvider {
  children: any;
}

export const languageResources = {
  en: {
    translation: translationEN,
  },
  ru: {
    translation: translationRU,
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources: languageResources,
    fallbackLng: SUPPORTED_LANGUAGES.RU,
    interpolation: {
      escapeValue: false,
    },
  })
  .then(() => {
    document.documentElement.lang = i18n.language;
  });

i18n.on('languageChanged', (lng) => {
  document.documentElement.lang = lng;
});

export const I18NProvider = (props: II18NProvider) => {
  return props.children;
};
