import { useCloudStorage } from '@vkruglikov/react-telegram-web-app';
import { useEffect } from 'react';
import { CS_KEYS, DAY_IN_MS } from 'shared/constants';
import { useGameStore, useGlobalStore } from 'shared/store';
import { DayLimitDonut } from 'shared/types';

export const useCheckDailyLimit = () => {
  const { getItem, removeItem, setItem } = useCloudStorage();
  const { setTodayCurrentLimit, setGameReady } = useGameStore();
  const { user, setCheckLimitLoading } = useGlobalStore();

  const clearLimit = async () => {
    await removeItem(CS_KEYS.TODAY_DONUTS_COUNT);
    await removeItem(CS_KEYS.DAY_COOLDOWN);
    await removeItem(CS_KEYS.LAST_START);
  };

  const checkUserLimitChange = async () => {
    const userLastSavedLimit = await getItem(CS_KEYS.USER_MAX_LIMIT);
    const dayCooldown = await getItem(CS_KEYS.DAY_COOLDOWN);

    if (dayCooldown && userLastSavedLimit && user.daily_limit > +userLastSavedLimit) {
      await clearLimit();
      setGameReady(true);
    }

    await setItem(CS_KEYS.USER_MAX_LIMIT, user.daily_limit.toString());
  };

  const handleCurrentLimit = async () => {
    const currentLimit = await getItem(CS_KEYS.TODAY_DONUTS_COUNT);

    if (!currentLimit) {
      setTodayCurrentLimit(0);
      return;
    }

    const currentLimitParsed: DayLimitDonut = JSON.parse(currentLimit);
    const timeDifference = Date.now() - +currentLimitParsed.timestamp;

    if (timeDifference >= DAY_IN_MS) {
      await clearLimit();
      setTodayCurrentLimit(0);
    } else {
      setTodayCurrentLimit(+currentLimitParsed.donuts);
    }
  };

  useEffect(() => {
    const checkLimits = async () => {
      try {
        await checkUserLimitChange();
        await handleCurrentLimit();
        setCheckLimitLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    checkLimits();
  }, [user.daily_limit]);
};
