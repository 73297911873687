export const getValuesForTimer = (seconds: number) => {
  const hours = `${Math.floor(seconds / 3600)}`;
  const minutes = `${Math.floor((seconds % 3600) / 60)}`;
  const secondsLeft = `${Math.floor(seconds % 60)}`;

  return {
    hours: hours.padStart(2, '0'),
    minutes: minutes.padStart(2, '0'),
    secondsLeft: secondsLeft.padStart(2, '0'),
  };
};
