import { GetRefsInfoResponse, GetRefsResponse, LeagueType, ReferralEntity } from 'shared/types';
import { create } from 'zustand';

type ReferralsStore = {
  totalRefs: number;
  totalLimitBBL: number;
  referrals: ReferralEntity[];
  league: LeagueType | null;
  setTotalRefs: (count: number) => void;
  setTotalBBL: (totalBBL: number) => void;
  setReferrals: (referrals: ReferralEntity[]) => void;
  setRefsStore: (data: Partial<GetRefsResponse & GetRefsInfoResponse>) => void;
};

export const useReferralsStore = create<ReferralsStore>((set) => ({
  totalRefs: 0,
  totalLimitBBL: 0,
  referrals: [],
  league: null,
  setTotalRefs: (totalRefs) => set(() => ({ totalRefs })),
  setTotalBBL: (totalBBL) => set(() => ({ totalLimitBBL: totalBBL })),
  setReferrals: (referrals) => set(() => ({ referrals })),
  setRefsStore: ({ count, data, total_limit_bbl, league }) =>
    set((state) => ({
      totalRefs: count ? count : state.totalRefs,
      totalLimitBBL: total_limit_bbl ? total_limit_bbl : state.totalLimitBBL,
      referrals: data ? data : state.referrals,
      league: league ? league : state.league,
    })),
}));
