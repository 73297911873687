import { GetSettingsResponse } from 'shared/types';
import { create } from 'zustand';

type GameSettings = Omit<GetSettingsResponse, 'daily_donut_limit'>;

type GameSessionStore = {
  currentDonutsCount: number;
  minGameDonuts: number;
  gameActive: boolean;
  frozen: boolean;
  gameReady: boolean;
  settings: GameSettings;
  gameCooldown: number;
  sessionId: string;
  todayCurrentLimit: number;
  catchedBees: number;
  failedDonuts: number;
  increaseDonutsFallSpeedInterval: number;
  cooldownTimerLoading: boolean;
  currentDonutFallSpeed: number;
  increaseDonutsCount: (count?: number) => void;
  setGameActive: (gameActive: boolean) => void;
  setCurrentDonutsCount: (currentDonutsCount: number) => void;
  setFrozen: (frosen: boolean) => void;
  setGameReady: (gameReady: boolean) => void;
  setSettings: (newSettings: Partial<GameSettings>) => void;
  setGameCooldown: (cooldown: number) => void;
  setSessionId: (sessionId: string) => void;
  setTodayCurrentLimit: (todayCurrentLimit: number) => void;
  increaseCatchedBees: (value: number) => void;
  increaseFailedDonuts: (value: number) => void;
  setCooldownTimerLoading: (value: boolean) => void;
  setCurrentDonutFallSpeed: (value: number) => void;
  increaseCurrentDonutFallSpeed: (multiplier: number) => void;
};

export const useGameStore = create<GameSessionStore>((set) => ({
  currentDonutsCount: 0,
  minGameDonuts: 3,
  gameActive: false,
  frozen: false,
  gameReady: false,
  catchedBees: 0,
  failedDonuts: 0,
  settings: {
    bbl_rate: 0,
    cooldown_duration: 2, //seconds
    created_at: '',
    donut_award_rate: 1,
    fall_bee_interval: 0.9, //seconds
    fall_bee_speed: 3, //seconds
    fall_donuts_interval: 1.1, //seconds
    fall_donuts_speed: 3, //seconds
    session_duration: 60, //seconds
    updated_at: '',
    uuid: '',
    waiting_time: 180, //seconds
  },
  gameCooldown: 240, //seconds
  sessionId: '',
  todayCurrentLimit: 0,
  increaseDonutsFallSpeedInterval: 10, //seconds
  cooldownTimerLoading: true,
  currentDonutFallSpeed: 3,
  increaseDonutsCount: (count = 1) =>
    set(({ currentDonutsCount }) => ({
      currentDonutsCount: currentDonutsCount + count,
    })),
  setGameActive: (gameActive) => set(() => ({ gameActive })),
  setCurrentDonutsCount: (currentDonutsCount) => set(() => ({ currentDonutsCount })),
  setFrozen: (frozen) => set(() => ({ frozen })),
  setGameReady: (gameReady) => set(() => ({ gameReady })),
  setSettings: (newSettings: Partial<GameSettings>) =>
    set((state) => ({ settings: { ...state.settings, ...newSettings } })),
  setGameCooldown: (cooldown: number) => set(() => ({ gameCooldown: cooldown })),
  setSessionId: (sessionId: string) => set(() => ({ sessionId })),
  setTodayCurrentLimit: (todayCurrentLimit) => set(() => ({ todayCurrentLimit })),
  increaseCatchedBees: (value: number) =>
    set((state) => ({ catchedBees: state.catchedBees + value })),
  increaseFailedDonuts: (value: number) =>
    set((state) => ({ failedDonuts: state.failedDonuts + value })),
  setCooldownTimerLoading: (cooldownTimerLoading: boolean) => set(() => ({ cooldownTimerLoading })),
  setCurrentDonutFallSpeed: (currentDonutFallSpeed: number) =>
    set(() => ({ currentDonutFallSpeed })),
  increaseCurrentDonutFallSpeed: (multiplier) =>
    set((state) => ({
      currentDonutFallSpeed: state.currentDonutFallSpeed - multiplier * state.currentDonutFallSpeed,
    })),
}));
