import styles from './style.module.scss';
import { LoadingContainer } from 'components';
import { Suspense } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { ROUTES } from 'shared/constants';

const NO_PADDING_ROUTES = [ROUTES.SESSION, ROUTES.CONGRATULATIONS, ROUTES.LIMIT_REACHED];

export const FullHeightLayout = () => {
  const location = useLocation();
  const noPadding = NO_PADDING_ROUTES.includes(location.pathname);

  return (
    <div className={styles.root} style={{ padding: noPadding ? '0' : '17px 17px 34px 17px' }}>
      <Suspense fallback={<LoadingContainer isGlobal={false} />}>
        <Outlet />
      </Suspense>
    </div>
  );
};
