import styles from './style.module.scss';
import { UIButton, UITypography } from 'shared/ui';
import { TaskEntity } from 'shared/types';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down-icon.svg';
import { TASK_STATUS } from 'shared/constants';
import { useTranslation } from 'react-i18next';

type Props = TaskEntity & {
  onClick: (task: TaskEntity) => void;
};

export const Task = ({
  title,
  icon,
  award_value,
  award_kind,
  status,
  assignment,
  id,
  description,
  condition,
  due_date,
  level,
  onClick,
}: Props) => {
  const { t } = useTranslation();

  const handleClick = () => {
    onClick({
      assignment,
      award_kind,
      award_value,
      condition,
      description,
      due_date,
      icon,
      id,
      level,
      status,
      title,
    });
  };

  const rightControl = () => {
    if (status === TASK_STATUS.TO_DO) {
      return (
        <div className={styles.arrowContainer}>
          <ArrowDownIcon className={styles.arrow} />
        </div>
      );
    }
    if (status === TASK_STATUS.PENDING) {
      return (
        <UIButton size="small2" fontVariant="Button2" className={styles.button}>
          {t('common.pending')}
        </UIButton>
      );
    }
    if (status === TASK_STATUS.DONE) {
      return (
        <UIButton size="small2" fontVariant="Button2" disabled>
          {t('common.completed')}
        </UIButton>
      );
    }

    return null;
  };

  return (
    <div className={styles.root} onClick={handleClick}>
      <div className={styles.infoContainer}>
        <UITypography variant="H2" component="p">
          {title}
        </UITypography>

        <UITypography
          iconLeft={
            <img
              src={'/donuts/blue-donut-29.png'}
              alt=""
              width={24}
              height={24}
              style={{ display: 'block' }}
            />
          }
        >
          {`+${award_value} ${award_kind?.toUpperCase()}`}
        </UITypography>
      </div>

      <div style={{ flexShrink: 0 }}>{rightControl()}</div>
    </div>
  );
};
