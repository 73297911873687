import { useEffect, useState } from 'react';
import { REFS_LIST_LIMIT } from 'shared/constants';
import { useReferralsStore } from 'shared/store';
import { getRefs, getRefsInfo } from '../queries/referrals';
import { AxiosResponse } from 'axios';
import { GetRefsResponse, GetRefsInfoResponse } from 'shared/types';
import { handleSettledPromise } from 'shared/utils';

export const useFetchRefs = () => {
  const { setRefsStore } = useReferralsStore();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRefs = async () => {
      try {
        const [refsRes, refsInfoRes] = await Promise.allSettled([
          getRefs(REFS_LIST_LIMIT, 0),
          getRefsInfo(),
        ]);

        handleSettledPromise<AxiosResponse<GetRefsResponse>>({
          promise: refsRes,
          callback: (result) => {
            setRefsStore({
              count: result.data.count,
              data: result.data.data,
            });
          },
        });

        handleSettledPromise<AxiosResponse<GetRefsInfoResponse>>({
          promise: refsInfoRes,
          callback: (result) => {
            setRefsStore({
              league: result.data.league,
              total_limit_bbl: result.data.total_limit_bbl,
            });
          },
        });
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchRefs();
  }, []);

  return { loading };
};
