export const ROUTES = {
  BAKERY: '/bakery',
  MAIN: '/',
  FRIENDS: '/friends',
  PROGRESS: '/progress',
  CHOOSE_LANGUAGE: '/choose-language',
  CHOOSE_FINGER: '/choose-finger',
  SESSION: '/session',
  CONGRATULATIONS: '/congratulations',
  SETTINGS: '/settings',
  MARKET: '/market',
  LIMIT_REACHED: '/limit-reached',
};
