import flyImage from 'assets/images/fly.png';

export const COMMON_PRELOAD_ASSETS = {
  sessionBgImage: '/session-bg.png',
  defaultBgImage: '/default-bg.png',
  mainScreenBgImage: '/main-screen-bg.png',
  bobeHero: '/bobe-hero.png',
  bobeHero2: '/bobe-hero2.png',
} as const;

export const PRELOAD_GAME_ASSETS = {
  flyImage,
} as const;
