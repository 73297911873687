type Params = {
  limitReached: boolean;
  dailyLimit: number;
  currentDailyLimit: number;
  earnedCurrency: number;
};

export const calculateEarnedCurrency = ({
  limitReached,
  dailyLimit,
  currentDailyLimit,
  earnedCurrency,
}: Params) => {
  const currentLimit = currentDailyLimit || 0;

  if (limitReached) {
    return dailyLimit - currentLimit;
  } else {
    return earnedCurrency;
  }
};
