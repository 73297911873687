import { useTranslation } from 'react-i18next';
import styles from './style.module.scss';
import { UIButton, UIModal, UITypography } from 'shared/ui';
import { useGlobalStore } from 'shared/store';
import { useState } from 'react';
import { copyTextLegacy, copyToClipboard } from 'shared/utils';
import { getUser } from 'shared/api';
import walletImage from 'assets/images/wallet-front-color.png';

type ConnectWalletModalProps = {
  visible: boolean;
  onClose: () => void;
  link: string;
  isAndroid: boolean;
};

export const ConnectWalletModal = ({
  visible,
  onClose,
  link,
  isAndroid,
}: ConnectWalletModalProps) => {
  const { t } = useTranslation();
  const { updateUser } = useGlobalStore();
  const [isLoading, setIsLoading] = useState(false);
  const [copyButtonText, setCopyButtonText] = useState(t('common.copy_link'));

  const onCopy = async () => {
    try {
      await copyToClipboard(link);
    } catch (error) {
      copyTextLegacy(link);
      console.log(error);
    } finally {
      setCopyButtonText(t('common.copied'));

      setTimeout(() => {
        setCopyButtonText(t('common.copy_link'));
      }, 1000);
    }
  };

  const updateUserWallet = async () => {
    setIsLoading(true);

    await getUser()
      .then(({ data }) => {
        updateUser({ wallet: data.wallet });

        if (data.wallet) {
          onClose();
        }
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <UIModal visible={visible} onClose={onClose} detent="content-height">
      <div className={styles.container}>
        <img src={walletImage} alt="" width={80} height={80} style={{ flexShrink: 0 }} />
        <UITypography variant="H1" component="p">
          {t('settings.connect_wallet_modal.connect_wallet')}
        </UITypography>

        {!isAndroid && (
          <UITypography component="p" className={styles.description}>
            {t('settings.connect_wallet_modal.non_android_text')}
          </UITypography>
        )}

        {isAndroid && (
          <>
            <UITypography component="p">
              {t('settings.connect_wallet_modal.android_text')}
            </UITypography>

            <UIButton fullwidth className={styles.btn} onClick={onCopy}>
              {copyButtonText}
            </UIButton>
          </>
        )}

        <UIButton disabled={isLoading} onClick={updateUserWallet} fullwidth className={styles.btn}>
          {t('common.check')}
        </UIButton>
      </div>
    </UIModal>
  );
};
