export const formatDonuts = (donuts: number) => {
  const roundedDonuts = Math.floor(donuts);

  if (roundedDonuts < 1_000) {
    return roundedDonuts.toString();
  }
  if (roundedDonuts < 1_000_000) {
    return (roundedDonuts / 1_000).toFixed(1) + 'K';
  }
  if (roundedDonuts < 1_000_000_000) {
    return (roundedDonuts / 1_000_000).toFixed(1) + 'M';
  }
  if (roundedDonuts < 1_000_000_000) {
    return (roundedDonuts / 1_000_000_000).toFixed(1) + 'B';
  }

  return (roundedDonuts / 1_000_000_000_000).toFixed(1) + 'T';
};
