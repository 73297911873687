import styles from './style.module.scss';
import { UIButton, UIModal, UIModalProps, UITypography } from 'shared/ui';
import { useTranslation } from 'react-i18next';
import { completeTask } from 'shared/api';
import { useEffect, useState } from 'react';
import { useWebApp } from '@vkruglikov/react-telegram-web-app';
import { TaskStatus } from 'shared/types';
import { useProgressStore } from 'shared/store/progress';
import { useGlobalStore } from 'shared/store';
import { TASK_STATUS, TOKEN_SHORTCUT } from 'shared/constants';

type TaskModalProps = Omit<UIModalProps, 'children'> & {
  href?: string;
  title: string;
  icon: string;
  taskId: string;
  status: TaskStatus;
  description: string;
  award: number;
  awardKind: string;
};

export const TaskModal = ({
  href,
  icon,
  onClose,
  title,
  visible,
  taskId,
  status,
  description,
  award,
  awardKind,
}: TaskModalProps) => {
  const { t } = useTranslation();
  const webApp = useWebApp();
  const [buttonText, setButtonText] = useState('complete');
  const [buttonVariant, setbuttonVariant] = useState<'primary' | 'secondary'>('primary');
  const [buttonDisabled, setbuttonDisabled] = useState(false);
  const [_status, setStatus] = useState<TaskStatus>(status);
  const [loading, setloading] = useState(false);
  const { updateTask } = useProgressStore();
  const { addUserBBL } = useGlobalStore();

  const onComplete = async () => {
    try {
      setloading(true);
      if (href && status === TASK_STATUS.TO_DO) {
        await webApp.openLink(href);
      }

      const res = await completeTask(taskId);

      if (res.data.status === TASK_STATUS.PENDING) {
        setButtonText('check');
        setbuttonVariant('secondary');
        setStatus(TASK_STATUS.PENDING);
        updateTask(res.data);
        return;
      }
      if (res.data.status === TASK_STATUS.DONE) {
        setButtonText('completed');
        setbuttonVariant('primary');
        setbuttonDisabled(true);
        setStatus(TASK_STATUS.DONE);
        updateTask(res.data);

        if (awardKind.toLowerCase().trim() === TOKEN_SHORTCUT.toLowerCase()) {
          addUserBBL(award);
        }

        return;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    if (status === TASK_STATUS.PENDING) {
      setButtonText('check');
      setbuttonVariant('secondary');
      setStatus(TASK_STATUS.PENDING);
    } else if (status === TASK_STATUS.DONE) {
      setButtonText('completed');
      setbuttonVariant('primary');
      setbuttonDisabled(true);
      setStatus(TASK_STATUS.DONE);
    }
  }, [status]);

  return (
    <UIModal visible={visible} onClose={onClose} detent="content-height">
      <div className={styles.content}>
        <img src={icon} alt="" width={80} height={80} />
        <UITypography variant="H1">{title}</UITypography>
        <UITypography component="p">{description}</UITypography>
        <UIButton
          variant={buttonVariant}
          disabled={buttonDisabled || loading}
          onClick={onComplete}
          fullwidth
        >
          {t(`common.${buttonText}`)}
        </UIButton>
      </div>
    </UIModal>
  );
};
