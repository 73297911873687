import styles from './style.module.scss';
import { NavLink } from 'react-router-dom';
import { useNavbarTabs } from 'shared/hooks/use-navbar-tabs';
import variables from 'shared/ui/theme/variables.scss';
import { UITypography } from 'shared/ui';

export const Navbar = () => {
  const { tabs } = useNavbarTabs();

  return (
    <div className={styles.navbar}>
      {tabs.map((tab) => (
        <NavLink key={tab.id} to={tab.path}>
          {({ isActive }) => (
            <div
              className={styles['navbar-item']}
              style={{
                border: `1px solid ${!isActive ? variables.colorBlack : variables.colorYellow}`,
                boxShadow: isActive ? variables.yellowShadow : undefined,
              }}
            >
              <div
                className={styles['navbar-item-content']}
                style={{
                  opacity: isActive ? 1 : 0.5,
                }}
              >
                {isActive ? tab.activeIcon : tab.icon}
                <UITypography variant="Menu">{tab.text}</UITypography>
              </div>
            </div>
          )}
        </NavLink>
      ))}
    </div>
  );
};
