import classNames from 'classnames';
import { CSSProperties, ButtonHTMLAttributes, ReactNode, MouseEventHandler } from 'react';
import styles from './style.module.scss';
import { TypographyVariant, UITypography } from '../typography/typography';

type ButtonVariant = 'primary' | 'secondary' | 'text';
type ButtonSize = 'small1' | 'small2' | 'default';
type CommonButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;
type Props = {
  children?: ReactNode | string | number;
  variant?: ButtonVariant;
  fullwidth?: boolean;
  size?: ButtonSize;
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  style?: CSSProperties;
  fontVariant?: TypographyVariant;
};

export const UIButton = ({
  children,
  className,
  variant = 'primary',
  fullwidth,
  size = 'default',
  fontVariant = 'Button1',
  ...props
}: Props & CommonButtonProps) => {
  const mergedClasName = classNames(
    styles[variant] || '',
    fullwidth ? styles.fullwidth : '',
    styles[size] || '',
    className,
  );

  return (
    <button className={mergedClasName} {...props}>
      <UITypography variant={fontVariant}>{children}</UITypography>
    </button>
  );
};
