import Lottie from 'react-lottie';
import animationData from 'assets/lottie/stars-donut.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const StarsDonut = () => {
  return <Lottie options={defaultOptions} height={142} width={142} />;
};
