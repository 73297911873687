import { m } from 'framer-motion';
import { CSSProperties, useMemo } from 'react';

type ScaleProps = {
  style?: CSSProperties;
  children: React.ReactNode;
  start?: boolean;
};

export const Scale = ({ children, style, start }: ScaleProps) => {
  const animate = useMemo(() => {
    if (typeof start === 'boolean') {
      return start ? { scale: 1, opacity: 1 } : undefined;
    }

    return { scale: 1, opacity: 1 };
  }, [start]);

  return (
    <m.div
      initial={{ scale: 0.8, opacity: 0 }}
      animate={animate}
      transition={{ delay: 0.1 }}
      style={style}
    >
      {children}
    </m.div>
  );
};
