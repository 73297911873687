import { ChangeEvent, ChangeEventHandler, InputHTMLAttributes, useRef, useState } from 'react';
import styles from './style.module.scss';

type UISliderProps = {
  onChange: ChangeEventHandler<HTMLInputElement>;
  defaultValue?: number;
} & InputHTMLAttributes<HTMLInputElement>;

export const UISlider = ({
  onChange,
  min = 0,
  max = 10,
  step,
  defaultValue,
  ...props
}: UISliderProps) => {
  const [value, setValue] = useState(defaultValue || +min);
  const sliderRef = useRef<HTMLInputElement>(null);

  const handleSliderChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(+event.target.value);
    onChange(event);

    if (!sliderRef.current) return;

    const _value = +event.target.value;
    const _min = sliderRef.current.min ? +sliderRef.current.min : 0;
    const _max = sliderRef.current.max ? +sliderRef.current.max : 100;
    const percentage = `${((_value - _min) / (_max - _min)) * 100}`;
    sliderRef.current.style.setProperty('--value', percentage);
  };

  return (
    <input
      ref={sliderRef}
      type="range"
      min={min}
      max={max}
      step={step}
      value={value}
      onChange={handleSliderChange}
      className={styles.slider}
      {...props}
    />
  );
};
