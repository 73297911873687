import styles from './style.module.scss';
import { DonutCounter } from './donut-counter';
import { GameTimer } from './game-timer';
import { useGameTimer } from 'shared/hooks';
import { useGameStore } from 'shared/store';
import { Swipe } from 'shared/ui/animations';

type Props = {
  stopGame: (limitReached?: boolean) => Promise<void>;
};

export const GamePanel = ({ stopGame }: Props) => {
  const { settings } = useGameStore();
  const gameTime = useGameTimer({ stopGame, startValue: settings.session_duration });

  return (
    <div className={styles.root}>
      <Swipe direction="vertical" from="top" style={{ width: '100%' }}>
        <div className={styles.container}>
          <GameTimer timerValue={gameTime} />
          <DonutCounter />
        </div>
      </Swipe>
    </div>
  );
};
