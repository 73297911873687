import { COMMON_PRELOAD_ASSETS } from 'shared/constants';
import styles from './style.module.scss';
import stars from 'assets/images/stars.png';

export const BobeHero = () => {
  return (
    <div className={styles['images-container']}>
      <img
        src={COMMON_PRELOAD_ASSETS.bobeHero}
        alt="bobe-hello"
        className={styles['bobe-hello-image']}
      />
      <img src={stars} alt="stars" className={styles.stars} />
    </div>
  );
};
