import styles from './style.module.scss';
import { LEAGUE_VARIANTS } from 'shared/constants';
import { UIModal, UITypography } from 'shared/ui';
import { LeagueVariant } from 'components/league-variant/league-variant';
import { useTranslation } from 'react-i18next';
import { ReactComponent as QuestionMarkIcon } from 'assets/icons/question-mark.svg';
import { useState } from 'react';
import { LeagueType } from 'shared/types';

type LeagueInfoModalProps = {
  leagueType: LeagueType;
};

export const LeagueInfoModal = ({ leagueType }: LeagueInfoModalProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <QuestionMarkIcon onClick={onOpen} />
      <UIModal onClose={onClose} visible={open} detent="content-height">
        <div className={styles.root}>
          <UITypography variant="H1" component="p" className={styles.title}>
            {t('league_info_modal')}
          </UITypography>

          {Object.values(LEAGUE_VARIANTS).map((league) => (
            <LeagueVariant
              key={league.id}
              league={league}
              isInfo
              active={leagueType === league.type}
            />
          ))}
        </div>
      </UIModal>
    </>
  );
};
